import App from '@/App.vue'
import i18n from '@/i18n'
import '@/icons'
import '@/index.css'
import router from '@/router'
import { Configuration, configureAxios, configureAxiosInterceptors, configureContainer, useSentry, useSessionStore } from '@rentsales/shared'
import '@rentsales/shared/index.css'
import axios from 'axios'
import { container, useInject } from 'inversify-hooks'
import { createPinia } from 'pinia'
import { createApp } from 'vue'

const { initSentry } = useSentry()

const axiosInstance = configureAxiosInterceptors(configureAxios(axios), () => {
  const sessionStore = useSessionStore()
  sessionStore.logout()
  router.replace({ name: 'signin' })
})

configureContainer(container, axiosInstance)
  .then(() => {
    const [config] = useInject<Configuration>(Configuration.name)
    const app = createApp(App)
    initSentry(app, router, config)
    app.use(i18n).use(createPinia()).use(router).mount('#app')
  })
  .catch((error: Error) => {
    console.error(error)
  })
