import de from '@/locales/de.json'
import { translations } from '@rentsales/shared'
import merge from 'lodash/merge'
import { createI18n, type I18nOptions } from 'vue-i18n'

const messages = { de: merge(translations.de, de) } as I18nOptions['messages']
export default createI18n({
  locale: 'de',
  messages,
  legacy: false
})
