import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBars,
  faBuilding,
  faCalendar,
  faCheck,
  faChevronDown,
  faChevronRight,
  faClipboardListCheck,
  faClockRotateLeft,
  faCog,
  faColumns3,
  faEllipsisVertical,
  faEnvelope,
  faFileCsv,
  faFloppyDisk,
  faFolder,
  faFolders,
  faGlobe,
  faGripDotsVertical,
  faGripLines,
  faListTimeline,
  faLocationPin,
  faMagnifyingGlass,
  faMemoCircleInfo,
  faPaperPlane,
  faPencil,
  faPlus,
  faRepeat,
  faRotateLeft,
  faSitemap,
  faSubtitles,
  faTrash,
  faTreePalm,
  faUser,
  faUserCrown,
  faUsers,
  faVirus,
  faWrench,
  faXmark
} from '@fortawesome/pro-light-svg-icons'

export const icons = [
  faUsers,
  faUser,
  faGlobe,
  faFolders,
  faMagnifyingGlass,
  faBuilding,
  faCog,
  faBars,
  faChevronRight,
  faFolder,
  faFolders,
  faSitemap,
  faTrash,
  faPencil,
  faSubtitles,
  faUserCrown,
  faEnvelope,
  faTreePalm,
  faVirus,
  faPaperPlane,
  faPlus,
  faCheck,
  faXmark,
  faChevronDown,
  faClipboardListCheck,
  faListTimeline,
  faCalendar,
  faFloppyDisk,
  faLocationPin,
  faGripLines,
  faMemoCircleInfo,
  faRepeat,
  faGripDotsVertical,
  faClockRotateLeft,
  faEllipsisVertical,
  faColumns3,
  faWrench,
  faFileCsv,
  faRotateLeft
]

library.add(...icons)
