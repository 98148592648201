import { Role, SigninFailed, useSessionStore } from '@rentsales/shared'
import { DateTime } from 'luxon'
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/HomeView.vue'),
      redirect: '/submissions',
      children: [
        {
          path: 'organisations',
          name: 'organisations',
          component: () => import('@/views/OrganisationsView.vue'),
          meta: { role: Role.Admin }
        },
        {
          path: 'organisations/:id',
          name: 'organisation',
          component: () => import('@/views/OrganisationDetailsView.vue'),
          meta: { role: Role.Admin }
        },
        {
          path: 'regions/:id',
          name: 'region',
          component: () => import('@/views/RegionDetailsView.vue'),
          meta: { role: Role.Admin }
        },
        {
          path: 'projects',
          name: 'projects',
          component: () => import('@/views/ProjectsView.vue'),
          meta: { role: Role.Admin }
        },
        {
          path: 'projects/:id',
          name: 'project',
          component: () => import('@/views/ProjectDetailsView.vue'),
          meta: { role: Role.Admin },
          children: [
            {
              path: 'teams',
              name: 'project/teams',
              component: () => import('@/views/ProjectTeamsView.vue'),
              props: true
            },
            {
              path: 'questionnaires',
              name: 'project/questionnaires',
              component: () => import('@/views/ProjectQuestionnairesView.vue')
            }
          ]
        },
        {
          path: 'questionnaires/:id',
          name: 'questionnaire',
          component: () => import('@/views/ProjectQuestionnaireDetailsView.vue'),
          meta: { role: Role.Admin }
        },
        {
          path: 'end-of-day-questionnaires',
          name: 'end-of-day-questionnaires',
          component: () => import('@/views/EndOfDayQuestionnairesView.vue'),
          meta: { role: Role.Admin }
        },
        {
          path: 'end-of-day-questionnaires/:id',
          name: 'end-of-day-questionnaire',
          component: () => import('@/views/EndOfDayQuestionnaireDetailsView.vue'),
          meta: { role: Role.Admin },
          props: (route) => ({ endOfDayQuestionnaireId: route.params.id }),
          children: [
            {
              path: 'assignments',
              name: 'end-of-day-questionnaire/assignments',
              component: () => import('@/views/EndOfDayQuestionnaireAssignmentsView.vue'),
              props: (route) => ({ endOfDayQuestionnaireId: route.params.id })
            },
            {
              path: 'questionnaire/:questionnaireId?',
              name: 'end-of-day-questionnaire/questionnaire',
              component: () => import('@/views/EndOfDayQuestionnaire.vue'),
              props: (route) => ({ questionnaireId: route.params.questionnaireId })
            }
          ]
        },
        {
          path: 'submissions',
          name: 'submissions',
          component: () => import('@/views/SubmissionsView.vue'),
          props: (route) => ({ date: route.query.date ? DateTime.fromFormat(route.query.date as string, 'yyyy-MM-dd', { zone: 'UTC' }).toJSDate() : new Date() })
        },
        {
          path: 'submissions/:id',
          name: 'submission',
          component: () => import('@/views/SubmissionDetailsView.vue'),
          props: (route) => ({ submissionId: route.params.id })
        },
        {
          path: 'leads/:id',
          name: 'lead',
          component: () => import('@/views/SubmissionDetailsView.vue'),
          props: (route) => ({ submissionId: route.params.id })
        },
        {
          path: 'users',
          name: 'users',
          component: () => import('@/views/UsersView.vue')
        },
        {
          path: 'users/:id',
          name: 'user',
          component: () => import('@/views/UserDetailsView.vue'),
          children: [
            {
              path: 'absences',
              name: 'user/absences',
              component: () => import('@/views/UserAbsencesView.vue'),
              props: (route) => ({ id: route.params.id })
            },
            {
              path: 'missing-submissions',
              name: 'user/missing-submissions',
              component: () => import('@/views/UserMissingSubmissionsView.vue'),
              props: (route) => ({ id: route.params.id })
            }
          ]
        },
        {
          path: 'leads',
          name: 'leads',
          component: () => import('@/views/LeadsView.vue'),
          meta: { role: Role.Admin }
        }
      ],
      meta: {
        authenticated: true
      }
    },
    {
      path: '/signin-redirect',
      beforeEnter: async () => {
        const sessionStore = useSessionStore()
        await sessionStore.initSession()
        return { name: 'home' }
      },
      component: () => {}
    },
    {
      path: '/signin',
      name: 'signin',
      component: () => import('@/views/SignInView.vue')
    },
    {
      path: '/access-denied',
      name: 'access-denied',
      component: () => import('@/views/AccessDeniedView.vue')
    },
    {
      path: '/signin-failed',
      name: 'signin-failed',
      component: SigninFailed
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]
})

router.beforeEach((to, _from, next) => {
  const sessionStore = useSessionStore()
  // is authentication required
  if (to.matched.some((record) => record.meta.authenticated)) {
    if (!sessionStore.isAuthenticated) {
      next({ name: 'signin' })
      return
    }

    if (to.matched.some((record) => record.meta.role !== undefined)) {
      if (sessionStore.session.role === undefined || sessionStore.session.role > (to.meta.role as Role)) {
        next({ name: 'access-denied' })
        return
      }
    }
  } else if (to.matched.some((record) => record.meta.anonymous) && sessionStore.isAuthenticated) {
    next({ name: 'home' })
    return
  }
  next()
})
export default router
